import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Views from "./views";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./translations/i18n";
import { useFirestoreConnect } from "react-redux-firebase";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/en-gb";
import i18next from "i18next";
import {
  FIRESTORE_INVITED_USERS_TABLE,
  FIRESTORE_USERS_TABLE,
} from "./constants/FirestoreConstant";

moment.locale(i18next.language);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  // Fetch invited users and CMS users
  useFirestoreConnect([
    { collection: FIRESTORE_INVITED_USERS_TABLE },
    {
      collection: FIRESTORE_USERS_TABLE,
      where: ["role", "in", ["cmsUser", "cmsAdmin"]],
      storeAs: "cmsUsers",
    },
  ]);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
};

export default App;
